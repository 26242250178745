<!-- 校务 -- 校务信息管理 -- 假期管理 -- 新增 -->
<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{})" :cancelRemind="false" confirmText="保存" cancelText="取消">
		

    <div class="form">
      <el-form :model="form" :rules="rules" ref="form" label-width="200rem" style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
        <el-form-item label="所属校区" prop="belong_school">
          <el-select v-model="form.belong_school" placeholder="请选择">
            <el-option v-for="item in schoolOptions" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="假期名称" prop="vacation_name">
          <el-input placeholder="请输入" maxlength="50" v-model.trim="form.vacation_name"></el-input>
        </el-form-item>
        <el-form-item label="假期开始时间" prop="vacation_start_time">
          <el-date-picker v-model="form.vacation_start_time" type="datetime" :editable="false" :picker-options="{
              disabledDate: (date) =>{
                  return date.getTime() < Date.now() - 86400000
                }
              }" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" @change="startChange" placeholder="请选择">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="假期结束时间" prop="vacation_end_time">
          <el-date-picker v-model="form.vacation_end_time" type="datetime" :editable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" :picker-options="endPickerOptions" placeholder="请选择">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="适用对象" prop="belong_user">
          <el-select v-model="form.belong_user" placeholder="请选择">
            <el-option v-for="item in selectConfig.belong_user" :label="item.belong_user_name" :value="item.belong_user"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用范围" prop="belong_range">
          <el-checkbox-group v-show="false" v-model="form.belong_range"></el-checkbox-group>
          <el-button @click="rangeVisible = true">点击选择</el-button>
          <div>
            <el-tag :key="tag.id" v-for="(tag, index) in checkedListTagShow" closable="" style="margin-right: 10rem" :disable-transitions="false" @close="deleteRange(index,tag.id)">
              {{ tag.name }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
      
    </div>

    <el-dialog title="选择范围" :visible.sync="rangeVisible" width="80%">
      <div>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15rem 0;"></div>
        <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in selectConfig.belong_range" :label="item.id" :key="item.id">{{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rangeSure">确 定</el-button>
      </div>
    </el-dialog>
  
</edit-template>
</template>

<script>
import moment from "moment";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    //检查结束时间是否大于开始时间
    let validatePass1 = (rule, value, callback) => {
      let startStr = this.form.vacation_start_time,
          endStr = this.form.vacation_end_time;
      let timeIsTrue = moment(startStr).format('x') < moment(endStr).format('x')
      if (!timeIsTrue) {
        callback(new Error('请仔细检查日期选项'));
      } else {
        callback()
      }
    };
    return {
      backType: 0,  //1为保存后返回，此时需要刷新list; 0为取消后返回，此时不需要刷新
      schoolOptions: [],  //校区配置
      selectConfig: {},  //适用范围、适用对象配置
      rangeVisible: false,  //适用对象弹出层开关
      endPickerOptions: {
        disabledDate: (date) => {
          return date.getTime() < moment(this.form.vacation_start_time).format('x') - 86399999
        }
      },

      //多选框相关 -element.ui
      checkAll: false,
      isIndeterminate: false,
      checkedList: [],
      checkedListTagShow: [],  //用于被选中的tag列表

      form: {
        belong_school: '',
        vacation_name: '',
        vacation_start_time: '',
        vacation_end_time: '',
        belong_user: '',
        belong_range: []
      },
      rules: {
        belong_school: [{required: true, message: "请选择所属校区"}],
        vacation_name: [{required: true, message: "请输入假期名称"}, {
          pattern: /^[\u4e00-\u9fa5\w@\-(\)（）]{1,50}$/,
          message: "请输入50字以内的汉字，数字、字母、@、-、(、)、（、）"
        }],
        vacation_start_time: [{required: true, message: "请选择假期开始时间"}],
        vacation_end_time: [{required: true, message: "请选择假期结束时间"}, {validator: validatePass1}],
        belong_user: [{required: true, message: "请选择适用对象"}],
        belong_range: [{required: true, message: "请选择适用范围"}]
      },
    }
  },
  created() {
    this.getConfig()

    //获取校区配置
    let obj = JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr
    Object.keys(obj).forEach(key => {
      this.schoolOptions.push({label: obj[key], value: Number(key)})
    })
  },
  methods: {
    //获取筛选配置
    getConfig() {
      this.$_axios2('/api/school-administration/vacation/vacation-screen',{logic:1}).then(res => {
        this.selectConfig = res.data
      })
    },

    startChange(e) {
      let time_start = moment(e).format('x')
      let time_end = moment(this.form.vacation_end_time).format('x')
      if (time_start >= time_end) {
        this.form.vacation_end_time = ''
      }
    },

    handleCheckAllChange(val) {
      if (val) {
        this.checkedList = this.selectConfig.belong_range.map(item => item.id)
      } else {
        this.checkedList = []
      }
      this.isIndeterminate = false;
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value ? value.length : this.checkedList.length;
      this.checkAll = checkedCount === this.selectConfig.belong_range.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.selectConfig.belong_range.length;
    },

    rangeSure() {
      let arr = [], value = this.checkedList
      this.selectConfig.belong_range.forEach(item => {
        value.includes(item.id) && arr.push(item)
      })
      this.checkedListTagShow = arr
      this.form.belong_range = [...this.checkedList]
      this.rangeVisible = false
    },

    deleteRange(index, id) {
      this.checkedListTagShow.splice(index, 1)
      this.checkedList.splice(this.checkedList.indexOf(id), 1)
      this.form.belong_range = [...this.checkedList]

      this.handleCheckedCitiesChange()
    },

    // 提交数据
    submit() {
      this.$refs.form.validate((res, e, p) => {
        if (res) {
          let params = {...this.form}
          params.belong_range = params.belong_range.join(',')
          this.$_axios2.post("/api/school-administration/vacation/add", params,{logic:1}).then(res => {
            this.$message({
              type: 'success',
              message: res.msg,
              onClose: () => {
                this.$store.commit("setPage", 1);
                this.$router.go(-1);
              }
            });
          })
        }
      })
    },
  },
}
</script>

<style scoped>

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
  min-width: 250rem;
}
</style>
